import React from "react"
import styled from "styled-components"
import ApplicationImage from "../images/kodiak-empty-racking-cropped-resized.jpg"

const ApplicationStyles = styled.div`
  background-color: whitesmoke;
  #container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;

    @media all and (-ms-high-contrast: none) {
      display: -ms-flexbox;
    }

    #application-content {
      background-color: whitesmoke;
      padding: 50px;

      @media all and (-ms-high-contrast: none) {
        width: 50%;
      }

      h1 {
        text-align: center;
      }

      ul {
        width: 100%;
        justify-self: center;
        line-height: 30px;
        font-size: small;
        padding: 10px;
      }
    }

    #media-content {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media all and (-ms-high-contrast: none) {
        width: 50%;
      }
    }

    @media (max-width: 850px) {
      grid-template-columns: 100%;
      max-height: 1400px;
    }
  }
`

const Application = props => {
  return (
    <ApplicationStyles>
      <div id="container">
        <div id="application-content">
          <h1>Application</h1>
          <ul>
            <li>Daily scrubbing & project work of smaller facilities</li>
            <li>
              Dedicated facility or multi-facility route cleaning programs
            </li>
            <li>Small rooms, detail & edge cleaning in larger facilities</li>
            <li>Chemical-free finish removal!</li>
            <li>Grout and porous floor scrubbing</li>
            <li>Entry-ways & lobbies</li>
            <li>Check-out aisles</li>
          </ul>
        </div>
        <div id="media-content">
          <img src={ApplicationImage} alt="" />
        </div>
      </div>
    </ApplicationStyles>
  )
}

export default Application
