import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import { ProductPageStyles } from "../components/styles/ProductPageStyles"
import ProductImage from "../images/260-tan.png"
import Application from "../components/Application"
import MediaImage from "../images/hero.png"

const ProductPage = () => {
  return (
    <MainLayout>
      <ProductPageStyles>
        <PageHeading
          pageHeading="S17D"
          // description="cray direct trade  readymade narwhal YOLO cornhole Kickstarter kitsch wayfarers artisan synth Vice seitan polaroid food truck Banksy fingerstache Thundercats art party cred chia next level Pinterest slow-carb before  Life disrupt"
        />
        <div id="product-container">
          <div id="image-container">
            <img src={ProductImage} alt="" />
          </div>
          <div id="product-desc-container">
            <h1>S17D Orbital Scrubber</h1>
            <p>
              Neutra wayfarers paleo whatever butcher skateboard dreamcatcher
              Carles meggings keytar sartorial try-hard banjo put a bird on it
              XOXO authentic viral twee VHS brunch hella Blue Bottle ugh
              semiotics plaid keffiyeh chia Pinterest fap PBR American Apparel
              YOLO typewriter craft beer pug direct trade letterpress chambray
            </p>
            <div id="download-container">
              <div className="download-box">Download Brochure</div>
              <div className="download-box">Download Spec Sheet</div>
              <div className="download-box">Download Parts Manual</div>
              <div className="download-box">Download Operator Manual</div>
            </div>
          </div>
        </div>
        <Application mediaImage={MediaImage} />
      </ProductPageStyles>
    </MainLayout>
  )
}

export default ProductPage
