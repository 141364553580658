import React from "react"
import styled from "styled-components"

const PageHeadingStyles = styled.div`
  h1 {
    /* width: 100%; */
    text-align: center;
    background-color: #ccd2c6;
    margin: 0;
    padding: 1rem;
    font-size: 1.7em;
  }
`

const PageHeading = props => {
  return (
    <PageHeadingStyles>
      <div id="content-container">
        <h1>{props.pageHeading}</h1>
        {/* <h3>{props.description}</h3> */}
      </div>
    </PageHeadingStyles>
  )
}

export default PageHeading
