import styled from "styled-components"

export const ProductPageStyles = styled.div`
  #product-container {
    display: grid;
    grid-template-columns: 50% 50%;

    #image-container {
      justify-self: right;
      img {
        width: 500px;
      }
      @media (max-width: 800px) {
        justify-self: center;
      }
    }
    #product-desc-container {
      justify-self: left;
      width: 60%;
      padding: 20px;
      p {
        line-height: 30px;
      }

      @media (max-width: 800px) {
        justify-self: center;
      }
    }
    #download-container {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .download-box {
        margin-top: 20px;
        width: 100px;
        height: 100px;
        padding: 5px;
        background-color: #ccd2c6;
        font-size: x-small;
      }
    }
    @media (max-width: 800px) {
      grid-template-columns: 100%;
    }
  }
`
